<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <v-container>
        <v-row class="align-center d-flex h-100 pb-0">
            <v-col xl="5" lg="5" sm="10" class="mx-auto">
                <checkmong-home-bar title=""></checkmong-home-bar>
                <v-row class="d-flex">
                    <v-col cols="12" style="text-align: center;">
                        <span class="text-purple" style="font-weight: 600;">VDO สอนการตรวจประเมิน</span>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-col cols="12">
                        <v-card class="" style="">
                            <v-card-text style="border-radius: 7px; padding-top: 25px">
                                <!-- <iframe  src="https://www.youtube.com/embed/KfYkzXTut1Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                 -->
                                 <span>สามารถใช้งานได้เร็วๆนี้</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>
    
        </v-row>
    
        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
    },
    data: () => ({
        arrayEvents: null,
    }),

    mounted() {
    },
    watch: {

    },
    methods: {},
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-main__wrap {
    padding-bottom: 10px;
}

iframe {
    width: 100%; 
    height: 35vh; /* 100/56.25 = 560/315 = 1.778 */
}
</style>
